const Header = () => {
    return <header  className='bg-white  pl-5 border-1 border-scogoddd top-0 sticky w-full z-50 flex items-center justify-between  '>

        <div className={`h-full w-full flex items-center justify-between `}>
            <div
                className='flex items-center cursor-pointer'
            >
                <div className='shrink-0'>
                    <img className='h-[calc(6.5vh-2vh)]' src={process.env.PUBLIC_URL + '/img/scogo-logo-black-1.png'} alt='Workflow' />
                </div>
            </div>

        </div>


    </header>
}
export default Header