import React from 'react';
import  SimpleButton  from './SimpleButton';

export const ButtonScogoPrimary = ({
    btnimgClass = 'w-4 h-4 mr-1',
    type,
    buttonIcon,
    Btnalt = 'img',
    textOrComponent,
    onClick,
    loading,
    disabled = false,
    buttonIconComponent,
    loaderColor,
    buttonClass = '',
    disabledClass = 'text-black flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-xl gap-1 font-medium relative cursor-not-allowed border-scogoddd bg-scogoddd',
    buttonPadding = 'px-4 py-2',
    loaderClass,
    style,
}) => {
    if (!disabled) disabled = loading;

    const btnClass = disabled
        ? `${disabledClass} ${buttonPadding}`
        : `text-white bg-scogoprimary flex items-center justify-center rounded-6px shadow-primaryBtnShadow text-font11 gap-1 font-medium relative  hover:bg-scogobgsky cursor-pointer ${buttonClass} ${buttonPadding}`;

    return (
        <SimpleButton
            type={type}
            loading={loading}
            btnClass={btnClass}
            onClick={onClick}
            disabled={disabled}
            loaderColor={loaderColor}
            buttonIcon={buttonIcon}
            buttonIconComponent={buttonIconComponent}
            btnimgClass={btnimgClass}
            Btnalt={Btnalt}
            textOrComponent={textOrComponent}
            loaderClass={loaderClass}
            style={style}
        />
    );
};


