import React from 'react';
import ErrorPage from './pages/ErrorPage';
import Header from './components/Header';
import ThankYou from './pages/ThankYou';
import { Outlet, Route, RouterProvider,createBrowserRouter, createRoutesFromElements, } from 'react-router-dom';
import AppointmentMainContainer from './components/AppointmentMainContainer';
import NoResultFound from './pages/NoResulFound';
import Footer from './components/Footer';
import { QueryClientProvider,QueryClient } from 'react-query'

function App() {
    const queryClient = new QueryClient()
   return (
    <QueryClientProvider client={queryClient}>
    
               <RouterProvider router={
                createBrowserRouter(
                    createRoutesFromElements(
                    <Route element={<AppLayout/>} errorElement={<NoResultFound/>}>
                     <Route path='/' element={<AppointmentMainContainer />}  />
                     <Route path='/success' element={<ThankYou />} />
                     <Route path='/failure' element={<ErrorPage />} />
                     <Route path='/linkExpired' element={ <NoResultFound/>} />
                     <Route path='*' element={<ErrorPage />} />
                     </Route>))
                } />
            
  </QueryClientProvider>
    );
}

export default App;

const AppLayout=()=>{
    return(<>
    
  
                <Header/>
                <Outlet/>
                <Footer/>
          
    </>)
}