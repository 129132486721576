export const transformKeys=({ticket, token, pageStatus, message} = {})=>{
    return{
        ticket:validateStringValue(ticket),
        token:validateStringValue(token),
        pageStatus:validateStringValue(pageStatus),
        message:validateStringValue(message),
        job_fk_pincode_id:validateStringValue(ticket?.job_fk_pincode_id),
        job_site_address_1:validateStringValue(ticket?.job_site_address_1 ),
        job_site_address_2:validateStringValue(ticket?.job_site_address_2),
        job_site_contact_mobile:validateStringValue(ticket?.job_site_contact_mobile),
        job_site_contact_name:validateStringValue(ticket?.job_site_contact_name ),
        description:validateStringValue(ticket?.description  ),
        closed_by_noc:validateNumberValue(ticket?.closed_by_noc),
        job_ticket_number:validateStringValue(ticket?.job_ticket_number ),
        fe_first_name:validateStringValue(ticket?.accepted_fe?.first_name),
        fe_last_name:validateStringValue(ticket?.accepted_fe?.last_name),
        fe_mobile:validateNumberValue(ticket?.accepted_fe?.mobile),
        fe_id:validateNumberValue(ticket?.accepted_fe?.id),
        cluster_first_name:validateStringValue(ticket?.cluster?.first_name),
        cluster_last_name:validateStringValue(ticket?.cluster?.last_name),
        cluster_mobile:validateNumberValue(ticket?.cluster?.mobile),
        cluster_id:validateNumberValue(ticket?.cluster?.id),
        city_name:validateStringValue(ticket?.site?.city?.city_name),
        external_site_id:validateStringValue(ticket?.site?.external_site_id),
        project_name:validateStringValue(ticket?.project?.project_name),
        state_name:validateStringValue(ticket?.site?.state?.state_name),
        execution_date:validateDateValue(ticket?.execution_date),

  }
}
const validateStringValue=(value)=>{
    if (value) return value;
    return '';
}

const validateNumberValue = (number) => {
    if (!isNaN(parseFloat(number))) return parseFloat(number);
    return 0;
};

const validateDateValue = (date) => {
    let timeStamp = Date.parse(date);
    if (isNaN(timeStamp)) return undefined;
    return date;
}