import React, { useState} from 'react';
import AppointmentCalendar from './AppointmentCalendar';
import AppointmentTimer from './AppointmentTimer';
import TicketDetailCard, { ClusterLead, FEDetails } from './TicketDetailCard';
import { useNavigate } from 'react-router-dom';
import { useQueries } from '../utils/common';
import TextModal from '../TextModal/TextModal';
import config from '../config';
import moment from 'moment';
import { transformKeys } from './common/TransformKeys';
import { useQuery } from 'react-query'
import { urls } from '../config/urls';

const AppointmentMainContainer = () => {
    const navigate = useNavigate();
    const [selectedDateAndTime, setSelectedDateAndTime] = useState({ date: '', time: '' });
    const token = useQueries().get('data');
    const [isModalOpen, setModalOpen] = useState(false);
    const [openSuccessTextModal,setOpenSuccessTextModal]=useState(false)
    const [submitResponseData,setSubmitResponseData]=useState({})
    const [isFormButtonLoading, setIsFormButtonLoading] = useState(false);
    const[isDateSelected,setIsDateSelected]=useState(false)

    const fetchData=async()=>{
        const queryParams = { data: token,};
        const queryString = new URLSearchParams(queryParams).toString();

        const apiUrl = `${config.api.lambdaBaseUrl}/${urls.getTicketAndFeDetails.url}?${queryString}`;
  
       const response = await fetch(apiUrl);
        const jsonData = await response.json();
        if(jsonData && jsonData?.code===200){
            const ticketAndFeDetail = transformKeys(jsonData?.data);
          return ticketAndFeDetail
        }
        if(jsonData?.code===400){
          
         
         navigate('/linkExpired',{ state: { executionDate: jsonData?.data?.executionDate}})
        
          } else if(jsonData?.code===500) {
          navigate('/failure');
        }   
        return {}           
    }
    let {isLoading,data}=useQuery({queryFn:fetchData,staleTime:30*1000});


 
  
    const submitData = async ({ date, time }) => {
        setIsFormButtonLoading(true);
        const fullDate = date.split('/');
        const dateTimeString = `${fullDate[2]}-${fullDate[1]}-${fullDate[0]} ${time.hr}:${time.min} ${time.time}`;
        const utcDate = moment(dateTimeString, 'YYYY-MM-DD h:mm A').utc();
        const body = {
            ticketId: data.ticket.id,
            token: data.token,
            selectedDate: utcDate,
            recipient: data.recipient,
        };

       const apiUrl = `${config.api.lambdaBaseUrl}/${urls.setExecutionDateForTicket.url}`;
       const response = await fetch(apiUrl, {
            method: urls.setExecutionDateForTicket.method,
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        var jsonData = await response.json();
        setSubmitResponseData(jsonData)
        setModalOpen(false);
        setIsFormButtonLoading(false);

        if (jsonData.code === 200 ) {
          if( jsonData.data.pageStatus==="Disable"){
                setOpenSuccessTextModal(true)
            }else{
              navigate('/success', { state: { selectedDateAndTime: selectedDateAndTime}});
            }
        }
        else if(jsonData.code===500) {
            navigate('/failure');
        }
        else if(jsonData.code===400){
            navigate('/linkExpired',{ state: { executionDate: jsonData?.data?.executionDate}})
        }
        else if(jsonData.code===404){
           navigate('/linkExpired')
        }
 };
  const openConfirmationModal = ({ date, time }) => {
        if (time && date) {
            setSelectedDateAndTime({ date, time });
            setModalOpen(true);
        } else {
            console.log('select time and date both');
        }
    };
 const onCancelClick = () => {
        setSelectedDateAndTime(pre=>({...pre,time:''}));
        setModalOpen(false);
    };
 return (
        <>
          <div className='mainParentContainer   '>
            <div className=' text-scogoprimary font-bold '>
                  {data?.ticket && data?.pageStatus==="Disable"?
                  <div className=' text-2xl text-scogoclosed pl-4 pt-6 h-24'> {data?.message}</div> 
                   :<>
                  <div className=' text-3xl pl-4 pt-6 '>Schedule A Field Engineer</div>
                  <div className='text-lg pt-2 pl-4'>Booking can be scheduled for up to 15 days.</div>
                  </>}   
            </div>
            <div className='mainContainer flex gap-4 flex-col '>
             
                <TicketDetailCard  loader={isLoading} ticketAndFeDetail={data} />
              
              
                <FEDetails Lable={'Name'} ticketAndFeDetail={data}  loader={isLoading} />
               
              
                {data?.cluster_id?  
                <ClusterLead Lable={'Name'} ticketAndFeDetail={data}  loader={isLoading} />
                :<></>}   
             
            
                <AppointmentCalendar  loader={isLoading} openConfirmationModal={openConfirmationModal} ticketAndFeDetail={data} selectedDateAndTime={selectedDateAndTime} setSelectedDateAndTime={setSelectedDateAndTime} setIsDateSelected={setIsDateSelected}/>
             
               
                <AppointmentTimer loader={isLoading} openConfirmationModal={openConfirmationModal} ticketAndFeDetail={data}  selectedDateAndTime={selectedDateAndTime} setSelectedDateAndTime={setSelectedDateAndTime} isDateSelected={isDateSelected}/>
     
            </div>
            </div>
            {isModalOpen && (
            <TextModal
                    onCancelClick={onCancelClick}
                    heading={'Book Appointment'}
                    text={`Do you want to set Appointment Date at ${selectedDateAndTime.time.hr}:${selectedDateAndTime.time.min} ${selectedDateAndTime.time.time} on ${selectedDateAndTime.date}?`}
                    closeOnBackdropClick={false}
                    isFormButtonLoading={isFormButtonLoading}
                    buttons={[
                        { showLoading: true,
                            textOrComponent: 'YES',
                            type: 'blue',
                            buttonClass: 'hover:bg-scogoprimary',
                            onClick: () => { submitData({ date: selectedDateAndTime.date, time: selectedDateAndTime.time })},
                        },
                        {  textOrComponent: 'NO',
                            type: 'red',
                            onClick: onCancelClick,
                            buttonClass: 'hover:bg-scogobgsky w-15',
                        } ]}
                />
            )}
            {(openSuccessTextModal && !isModalOpen)&&
            <TextModal
               heading={'Set Appointment Failed'}
               text={submitResponseData.data.message}
               closeOnBackdropClick={false}
               headerClass={'text-font24 text-scogo30 mb-2 text-scogoclosed font-bold'}
            />}
        </>
    );
};
export default AppointmentMainContainer;

