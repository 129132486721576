import Cookies from 'universal-cookie';
import config from '../config';
import { useLocation } from 'react-router-dom';
import React from 'react';


const cookies = new Cookies();


export const getJWTToken = () => {
    return cookies.get(`${config.name}-authorization`);
};

export function useQueries() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
