import { useLocation } from 'react-router-dom';
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Sepetember', 'October', 'November', 'December'];
const ThankYou = () => {
  const location = useLocation();
    const { selectedDateAndTime } = location.state || {};
    let timeToShow = `Your appointment is booked on`;
    if (selectedDateAndTime.date && selectedDateAndTime.time) {
        let fullDate = selectedDateAndTime.date.split('/');
        let time = selectedDateAndTime.time;
        let date = fullDate?.[0] || '';
        let month = months?.[parseInt(fullDate?.[1] - 1)] || '';
        let year = fullDate?.[2] || '';
        if (date) timeToShow = `${timeToShow} ${month} ${date}, ${year} at ${time.hr} : ${time.min} ${time.time}`;
    }
return (
        <>
            <div className='flex flex-col items-center justify-center gap-8 w-93.5vw pt-4 errorPage'>
                <div  style={{fontSize: '50px',fontWeight: '700'}}>Thank You!</div>
                <img className='h-[10rem] w-[15rem]' src={process.env.PUBLIC_URL + '/img/Tick.png'} alt='Workflow' />
                <div className='text-4xl pl-20 pt-20 '>{timeToShow}</div>
            </div>
        </>
    );
};

export default ThankYou;
