import { CardHeader } from './TicketDetailCard';
import { useState, useEffect,} from 'react';
import { SkeltonCalendar, SkeltonCardOne } from './common/SkeletonLoader';

import moment from 'moment';
const AppointmentCalendar = ({ openConfirmationModal, loader, ticketAndFeDetail, selectedDateAndTime, setSelectedDateAndTime,setIsDateSelected}) => {
    return (
        <>
          
                <div className='calendar  bg-white'>
                    
                     <div style={{ fontSize: '1.2rem' }} className='border-1 border-scogoprimary blueHeader bg-scogoprimary flex px-[1vw]  items-center text-white  rounded-t-6px'>
                        Select Date
                      </div>
                      {loader ? (
                <SkeltonCalendar />
            ) : (
                        <div className=' calendarWhiteDiv bg-white rounded-md  h-full pb-10 grid place-items-center'>
                            <Calendar openConfirmationModal={openConfirmationModal} ticketAndFeDetail={ticketAndFeDetail}
                             selectedDateAndTime={selectedDateAndTime} setSelectedDateAndTime={setSelectedDateAndTime}
                             setIsDateSelected={setIsDateSelected}
                            />
                        </div>
                       )}
                </div>
         
        </>
    );
};
export default AppointmentCalendar;

const Calendar = ({ openConfirmationModal, ticketAndFeDetail,selectedDateAndTime, setSelectedDateAndTime ,setIsDateSelected}) => {
  
    const today = new Date();
    const currentDate = today;
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    const [calendarDays, setCalendarDays] = useState([]);
    const [dateClicked, setDateClicked] = useState(null);
    const [isCalendarDisable, setIsCalendarDisable] = useState(false);
    const firstDayOfMonth = new Date(currentYear, currentMonth, 1).getDay();

    useEffect(() => {
        const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
        const days = [];
        const prevMonthDays = new Date(currentYear, currentMonth, 0).getDate();

        const totalDays = daysInMonth + firstDayOfMonth + (60 - daysInMonth + firstDayOfMonth);
        let count = 0;

        for (let i = 1; i <= totalDays; i++) {
            let date;
            let highlight = false
            if (i <= firstDayOfMonth) {
                date = new Date(currentMonth === 0 ? currentYear - 1 : currentYear, currentMonth - 1, prevMonthDays - firstDayOfMonth + i);
            } else if (i <= daysInMonth + firstDayOfMonth) {
                date = new Date(currentYear, currentMonth, i - firstDayOfMonth);
            } else {
                const day = new Date(today.getFullYear(), currentMonth <= 10 ? +currentMonth + 1 : 0, i - (daysInMonth + firstDayOfMonth));
                date = new Date(currentMonth === 11 ? currentYear + 1 : currentYear, currentMonth <= 10 ? +currentMonth + 1 : 0, day.getDate());
            }
            if(date > new Date(moment(today).subtract(1, 'days'))  && count < 15){
              highlight = true;
              count++;
            }
            days.push({date, highlight});
        }
      setCalendarDays(days);
    }, []);

    useEffect(() => {
        if (ticketAndFeDetail?.ticket && ticketAndFeDetail?.pageStatus === 'Disable') {
            setIsCalendarDisable(true);
        }
    }, [ticketAndFeDetail?.ticket, ticketAndFeDetail?.pageStatus, firstDayOfMonth]);

    const handleDateClick = (fulldate, isHighlighted, dayNumber, isCalendarDisable) => {
        if (isHighlighted && !isCalendarDisable) {
            openConfirmationModal({ date: fulldate, time: selectedDateAndTime.time });
            setSelectedDateAndTime((pre) => ({ ...pre, date: fulldate }));
            setDateClicked(dayNumber);
            setIsDateSelected(true);
        }
    };

    return (
       <>
            <div style={{ fontSize: '30px' }} className='flex text-scogoprimary font-medium text-2xl justify-center items-center h-[10vh] calendarMonth'>
                {currentDate.toLocaleString('default', { month: 'long' })} {currentYear}
            </div>
            <table className=' w-[90%]  h-[45vh]  calendarTable'>
                <thead>
                    <tr>
                        <th className='text-xl '>Sun</th>
                        <th className='text-xl '>Mon</th>
                        <th className='text-xl '>Tue</th>
                        <th className='text-xl '>Wed</th>
                        <th className='text-xl '>Thu</th>
                        <th className='text-xl '>Fri</th>
                        <th className='text-xl '>Sat</th>
                    </tr>
                </thead>
                <tbody className='text-xl w-6 '>
                    {Array(7)
                        .fill()
                        .map((week, weekIndex) => (
                            <tr key={weekIndex} className=''>
                                {Array(7)
                                    .fill()
                                    .map((day, dayIndex) => {
                                        const dayNumber = dayIndex + 1 + weekIndex * 7;
                                        const currentIndexDate = calendarDays[dayNumber - 1]?.date;
                                        const isCurrentMonth = currentIndexDate && currentIndexDate.getMonth() === currentMonth;
                                        let current_date = currentIndexDate && currentIndexDate.getDate();
                                        const isHighlighted = currentIndexDate && calendarDays[dayNumber - 1]?.highlight;
                                        let current_month = currentMonth + 1;
                                        let current_year = parseInt(currentYear);
                                        if (current_month < 12 && !isCurrentMonth) current_month += 1;
                                        else if (current_month === 12 && !isCurrentMonth) {
                                            current_month = 1;
                                            current_year += 1;
                                        }
                                        let fulldate = `${current_date}/${current_month}/${current_year}`;

                                        return (
                                            <td
                                                key={dayIndex}
                                                className={`text-center w-5 ${isHighlighted ? 'cursor-pointer' : ' text-gray-300 cursor-not-allowed'} `}
                                                style={isCalendarDisable ? { color: 'lightgray' } : {}}
                                                onClick={() => handleDateClick(fulldate, isHighlighted, dayNumber, isCalendarDisable)}
                                            >
                                                <span
                                                    className={`text-center w-5 px-7 py-1 ${dayNumber === dateClicked ? 'bg-scogoprimary rounded-2xl text-white  ' : ''}
                      ${current_date === today.getDate() && isCurrentMonth ? 'bg-scogoddd rounded-2xl ' : ''} `}
                                                >
                                                    {current_date}
                                                </span>
                                            </td>
                                        );
                                    })}
                            </tr>
                        ))}
                </tbody>
            </table>
            </>
    );
};

