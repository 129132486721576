import React from 'react';
import { SkeltonCardOne } from './common/SkeletonLoader';
import moment from 'moment'
import IconToolTip from './common/IconToolTip';
const TicketDetailCard = ({loader, ticketAndFeDetail, error}) => {
   
       let branchname=ticketAndFeDetail?.ticket?.site?.site_branch_name||''
       let pincode=ticketAndFeDetail?.job_fk_pincode_id||''
       let city=ticketAndFeDetail?.city_name||''
       let state=ticketAndFeDetail?.state_name||''
       let siteAddress=ticketAndFeDetail?.job_site_address_1
       let adderess= siteAddress+' '+branchname +' ' +pincode+' '+ city+' '+state
       let exDate= 'Yet to be assign'
if(ticketAndFeDetail?.execution_date && moment(ticketAndFeDetail?.execution_date).isValid()){
    exDate=moment(ticketAndFeDetail?.execution_date).format('DD-MM-YYYY HH:mm')
}
 return (
        <>
            <div className='flex  flex-col overflow-hidden h-full bg-white ticketCard'>
              
            <div style={{ fontSize: '1.2rem' }} className='border-1 border-scogoprimary  bg-scogoprimary flex px-[1vw]  items-center text-white  rounded-t-6px blueHeader'>
                     Ticket Details
                       </div>
                       {loader?<SkeltonCardOne arrayLength={8}/>:
                        <div className=' flex flex-col gap-3  bg-white rounded-md  overflow-hidden p-[1rem]  ticketCardWhiteDiv'>
                            <CardDetails Lable={'Ticket:'} value={ticketAndFeDetail?.job_ticket_number} />
                            <CardDetails Lable={'Project:'} value={ticketAndFeDetail?.project_name} />
                            <CardDetails Lable={'Site Id:'} value={ticketAndFeDetail?.external_site_id} />
                            <CardDetails Lable={'Name:'} value={ticketAndFeDetail?.job_site_contact_name} />
                         { ticketAndFeDetail?.job_site_contact_mobile&&
                           <CardDetails Lable={'Mobile:'} value={ticketAndFeDetail?.job_site_contact_mobile} />}
                           {(ticketAndFeDetail?.description!=='null')&&
                            <CardDetails Lable={'Discription:'} value={ticketAndFeDetail?.description} />}
                            <CardDetails Lable={'Execution Date:'} value={exDate} />
                           {adderess&& <CardDetails Lable={'Site Address:'} value={adderess} 
                           valueClass='pl-[0%]' />}
                       </div>}
          </div>
        </>
    );
};
export default TicketDetailCard;

export const CardDetails = ({ Lable, value }) => {
    return (
        <>
           <div className='inline-flex items-baseline px-2 '>
                <div className='font-medium min-w-max'style={{fontSize: '1.2rem',}}>
                    {Lable}
                </div>
                <div className='pl-2 '>
                    <IconToolTip title={`${value}`}>
                        <div style={{ fontSize: '1.2rem', }}className={'text-grayText  text-center font-medium line-clamp-3'} >
                            {value}
                        </div>
                    </IconToolTip>
                </div>
            </div>
       </>
    );
};

export const ClusterLead = ({ ticketAndFeDetail,loader}) => {
    const name=ticketAndFeDetail?.cluster_first_name +' '+ticketAndFeDetail?.cluster_last_name||" "
   return (
        <>
        
          <div className='  clusterCard bg-white'>
                 <div style={{ fontSize: '1.2rem' }} className='border-1 border-scogoprimary blueHeader  bg-scogoprimary flex px-[1vw]  items-center text-white  rounded-t-6px'>
                    Cluster Details
                 </div>
                 {loader?<SkeltonCardOne arrayLength={1}/>:
                <>
                  <div className='  bg-white  gap-2 p-[1rem] rounded-b-md flex'>
                    <div className='w-[60%]'>
                    <CardDetails value={name||" "} /> 
                    </div>
                  
                        <CardDetails value={ticketAndFeDetail?.cluster_mobile} />
                  </div></> } 
          </div>
         </>
    );
};
export const FEDetails = ({ticketAndFeDetail,loader }) => {
    const name=ticketAndFeDetail?.fe_first_name+' '+ticketAndFeDetail?.fe_last_name||" "
    return (
        <>
         <div className=' feCard bg-white   '>
         <div style={{ fontSize: '1.2rem' }} className='border-1 border-scogoprimary blueHeader bg-scogoprimary flex px-[1vw] items-center text-white  rounded-t-6px w-full '>
            FE Details
         </div>
         {loader?<SkeltonCardOne arrayLength={1}/>:
         <>
         {ticketAndFeDetail?.fe_id ?
         <div className='bg-white p-[1rem] rounded-b-md  flex  '>
            <div className='w-[60%]'>
            <CardDetails  value={name} />
            </div>
       <div>
       <CardDetails  value={ticketAndFeDetail?.fe_mobile ||' '} /> 
       </div>
   
         </div>:
         <div className=' bg-white pb-4 rounded-b-md  grid place-items-center '>
         <CardDetails  value={' Not assign'} />  
         </div> } 
         </>} 
       </div> 
      </>
 )
};