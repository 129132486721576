import React from 'react';
import Popup from './Popup';
import { ButtonScogoClosed, ButtonScogoPrimary } from '../components/Button';
const TextModal = (props) => {
    const { heading, text, buttons, closeOnBackdropClick, onCancelClick ,headerClass='text-font24 text-scogo30 mb-2 text-scogoprimary font-bold',isFormButtonLoading} = props;


    let buttonsUI =buttons?.length>0&& buttons.map((button, index) => {
        const buttonLookup = {
            red: ButtonScogoClosed,
            blue: ButtonScogoPrimary
        };

        const ButtonComponent = buttonLookup[button.type];

        return (
            <ButtonComponent
                key={`text_modal_${index}`}
                textOrComponent={button.textOrComponent}
                onClick={button.onClick}
                loading={button.showLoading && isFormButtonLoading}
                buttonClass={button.buttonClass}
            />
        );
    });

    return (
        <Popup closeOnBackdropClick={closeOnBackdropClick} onCancelClick={onCancelClick} showClose={buttons?.length>0}>
            <p className={headerClass}>{heading}</p>
            <p className='text-scogogray text-font16'>{text}</p>
            <div className='gap-2 flex items-center justify-center mt-8'>{buttonsUI}</div>
        </Popup>
    );
};

export default TextModal;
