import React from 'react'

const Loader = ({ thickness='3', color='#F48A21', size='25', speed='1', ...rest }) => {

    const borderTop = thickness + 'px solid ' + color
    const width = size + 'px'
    const height = size + 'px'

    //what if this value is not passed?

    const loaderStyle = {
        borderRadius: '50%',
        borderTop,
        width,
        height,
        ...rest,
    }
     return (
        <center>
            <div style={loaderStyle} className='animate-spin'>

            </div>
        </center>
    )
}

export default Loader
