import React from 'react';
import moment from 'moment';

function Footer() {
    return (
        <footer className=' sticky bottom-0  bg-white  border-t border-scogoddd w-full z-[100] flex items-center justify-between'>
        <span className=' text-scogoprimary  font-medium pl-4'> 
        Copyright © {moment().year()} Scogo. All Rights Reserved.
         </span>
       </footer>
    );
}

export default Footer;
