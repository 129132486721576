import { useLocation} from 'react-router-dom';
import moment from 'moment';
const NoResultFound=()=>{
    const location = useLocation();
    const { executionDate } = location.state || {}
    const showExDate=executionDate
    let exDate=moment( executionDate).format('DD-MM-YYYY')
    let exTime=moment(executionDate).format(' HH:mm')
return(<>
    <div className='flex items-center justify-center flex-col gap-[2rem] pt-[5rem]  bg-scogoerrbg errorPage'>
    <img className='h-[15rem]' src={process.env.PUBLIC_URL + '/img/opps.png'} alt='Workflow' />
    {showExDate? 
    <> <div className='text-[30px] pl-20'>Link Expired, Request new link.</div>
    <div className='text-[30px] pl-20'> Your current appointment date is {exDate} at {exTime}</div></>:
    <div className='text-[30px] pl-20'>Link Expired, Request new link.</div>
    }
   </div>
   </>)
}
export default NoResultFound