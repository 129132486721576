import React from 'react';
import Loader from '../common/Loader';


const SimpleButton = (props) => {
    const { type, loading, btnClass, loaderClass, onClick, disabled,  buttonIcon, buttonIconComponent, btnimgClass, Btnalt, textOrComponent, style, buttonTextClass = '' } =
        props;

    return (
        <>
            <button type={type} className={btnClass} onClick={onClick} disabled={disabled} style={style} >
                {loading && (
                    <div className={`${loaderClass ? loaderClass : 'absolute'}`}>
                        <Loader color={ '#FFF'} size='15' speed='1' thickness='3' />
                    </div>
                )}

                {buttonIcon && <img src={process.env.PUBLIC_URL + buttonIcon} className={btnimgClass} alt={Btnalt} />}
                {buttonIconComponent && buttonIconComponent}
                <div className={`${loading ? 'invisible' : ''} ${buttonTextClass}`}>{textOrComponent}</div>
            </button>
        </>
    );
};
export default SimpleButton
