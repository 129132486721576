import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

export const SkeltonCardOne = ({arrayLength}) => {
    return (
        
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, paddingTop: 2 }}>
            {Array.from({ length: arrayLength }).map((elem, index) => {
                return (
                    <div className='mt-2 ' key={index}>
                        <Skeleton sx={{ background: 'rgb(221,221,221)', }} variant='rectangular' width={'full'} height={25} animation='wave' />
                    </div>
                );
            })}
        </Box>
    );
};


export const SkeltonCalendar = () => {
    return (
     
        <Box sx={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 2, paddingTop: 2 }}>
           
               
                        <Skeleton sx={{ background: 'rgb(221,221,221)', }} variant='rectangular' height={'15vh'}  animation='wave' />
                        <div className='grid grid-cols-4 gap-10'>
                        {Array.from({ length: 16 }).map((elem, index) => {
                            return(
                               
                        <Skeleton sx={{ background: 'rgb(221,221,221)', marginTop:'3vh',}} variant='rectangular'  height={50} animation='wave' />
                     
                        )
                    })}
                       </div>
                      
              
        
        </Box>
    );
};


export const SkeltonTimer = () => {
    return (
        
        <Box sx={{ paddingLeft: 1, paddingRight: 1, paddingBottom: 1, paddingTop: 1 }}>
                    <div className='grid grid-cols-2 gap-4'>
            {Array.from({length:22}).map((elem, index) => {
                return (
                    <div className='mt-2 ' key={index}>
                        <Skeleton sx={{ background: 'rgb(221,221,221)', }} variant='rectangular' width={'full'} height={'4vh'} animation='wave' />
                    </div>
                );
            })}
            </div>
        </Box>
    );
};
